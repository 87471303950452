<script setup>
document.body.addEventListener(
  "touchmove",
  function (e) {
    if (
      !e.target.classList.contains("search-modal-result-item") &&
      !e.target.classList.contains("search-result-title") &&
      !e.target.classList.contains("search-result-info") &&
      !e.target.classList.contains("search-result-source") &&
      !e.target.classList.contains("search-result-pubTime")
    ) {
      e.preventDefault();
    }
  },
  {
    passive: !1,
  }
);

let browserList = ["ucbrowser", "huaweibrowser"];
let ua = navigator.userAgent.toLocaleLowerCase();
let browserNeedFixedFlag = 0
browserList.forEach(item => {
  if(ua.indexOf(item) > -1) {
    browserNeedFixedFlag += 1
  }
})
provide("UA", browserNeedFixedFlag);




import { ref, provide } from "vue";
import useGlobal from "@/composables/useGlobal";
provide("useGlobalHook", useGlobal());



</script>

<template>

  <router-view></router-view>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 28px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #696969;
  position: relative;
  margin: auto;
  width: 750px;
  height: 100vh;
  min-height: 100vh;
  min-height: 100%;
  overflow: hidden;

  background-color: #f1f2f4;

  // display: flex;
  // flex-direction: column;

}
</style>
